/** @jsx jsx */
import { jsx, Styled, Button} from 'theme-ui'
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export const PaymentInfo = ({step, order, values, setValues}) => {


  const stripePromise = loadStripe('pk_test_jaOEBtaOWoJh2e7eUKhg5Qd9');

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        padding: "8px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
  
      if (result.error) {
        // Show error to your customer.
        console.log(result.error.message);
      } else {
        console.log(result.token);
        fetch('https://staging.fitnessculture.com/membersignup/endpoint', {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({...values, stripeToken: result.token.id}),
        })
          .then(response => response.json())
          .then(data => {
            console.log(data)
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <div sx={{boxShadow: "small", padding: 4, border: "1px solid #e0e0e0", borderRadius: "small"}}>
          <CardElement options={CARD_ELEMENT_OPTIONS}/>
        </div>
        <Button variant="primary" sx={{position: "absolute", bottom: "16px", right: "16px"}} type="submit">Submit</Button>
      </form>
    );
  };

  if (step === order ) {
    return(

      <div>  
        <Styled.h5>Payment Info</Styled.h5>   
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    )
  } else {
    return null
  }
}