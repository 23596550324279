import React, { useState } from 'react'
import { SiteContext } from '../context'

export const SiteProvider = ({ children }) => {

  let initialState = {
    showDrawer: false,
  }

  const [website, setWebsite] = useState(initialState)

  return (
    <SiteContext.Provider
      value={{
        website,
        toggleDrawer: (e, value) => {
          e.preventDefault();
          if (website.showDrawer === true) {
            window.history.pushState("", "Page Title", "/");
          } else {
            window.history.pushState("", "Page Title", "/sign-up");
          }
          setWebsite(prevState => {
            return {...prevState, showDrawer: !value}
          })
        }
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
