/** @jsx jsx */
import React, { useContext, useState} from "react"
import { jsx, Box, Styled, Flex, Grid, Input, Button } from 'theme-ui'
import {UserInfo, PaymentInfo, ProgramInfo} from "../SignUp"
import { SiteContext } from '../../context'
import "../../styles/modal.css"
import "../../styles/global.css"


export const SignUp = () => {
  const { website: {showDrawer}, toggleDrawer } = useContext(SiteContext)

  const [values, setValues] = useState({
    id: "11",
    code: "c1881e918c7f5971b65fd2344680cfd0",
    name: "",
    first_name: "",
    last_name: "",
    password: "",
    username: "",
    email: "",
    phone: "",
    member_agrees: "true",
    program_start_date: "",
    promo_code: "",
    total_check: "49.99",
    stripeToken: ""
  })

  console.log(values)



  const stepCount = 3
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    let {name, value} = event.target
    if (name === "program_start_date") {
      const dateArray = value.split("-")
      console.log(dateArray)
      let day = dateArray[2]
      let month = dateArray[1]
      let year = dateArray[0]
      value = `${month}/${day}/${year}` 
    }
    setValues({...values, [name]: value})
  }


  const next = () => {
    let currentStep = step
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= stepCount ? stepCount + 1 : currentStep + 1
    setStep(currentStep)
  }
    
  const prev = () => {
    let currentStep = step
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    setStep(currentStep)
  }




  // The "next" and "previous" button functions
const previousButton =() =>{
  let currentStep = step;
  // If the current step is not 1, then render the "previous" button
  if(currentStep !== 1){
    return (
      <Button
        variant="light"
        onClick={prev}
        sx={{position: "absolute", left: "16px", bottom: "16px"}}>
        Previous
      </Button>
    )
  }
  // ...else return nothing
  return null
}

const nextButton = () => {
  let currentStep = step;
  // If the current step is not 3, then render the "next" button
  if(currentStep < stepCount && currentStep > 0){
    return (
 
        <Button
          onClick={next}
          sx={{position: "absolute", right: "16px", bottom: "16px"}}
          >  
          Next
        </Button>        

    )
  } else if(currentStep === stepCount) {
    return (
      // <Flex sx={{justifyContent: "flex-end", width: "100%"}}>
      //   <Button
      //     type="button" 
      //     onClick={handleSubmit}>
      //     Submit
      //   </Button>  
      // </Flex>
      null
    )
  }
  // ...else render nothing
  return null;
}


  return(
    <div>
      <div onClick={(e) => toggleDrawer(e, showDrawer)} className={showDrawer ? 'show-opaque': 'hidden-opaque'}></div>
      <div sx={{backgroundColor: "rgb(247, 247, 247);", width: '100%', maxWidth: ['100%', '100%', 'small'], boxShadow: 'large'}} className={showDrawer ? 'show-right' : 'hidden-right'}>
        <Flex sx={{backgroundColor: 'light', height: "56px", justifyContent: 'space-between', alignItems: "center", px: 4,  borderBottom: "1px solid #c8c7c7"}}>
          <Flex sx={{alignItems: "center", justifyContent: "center"}}>
            <Styled.a onClick={(e) => toggleDrawer(e, showDrawer)} sx={{fontSize: "1.5rem"}}>
              <svg style={{width: "16px"}}viewBox="0 0 16 16"><path clipRule="evenodd" d="M15.78.22a.75.75 0 0 1 0 1.06L9.06 8l6.72 6.72a.75.75 0 1 1-1.06 1.06L8 9.06l-6.72 6.72a.75.75 0 0 1-1.06-1.06L6.94 8 .22 1.28A.75.75 0 1 1 1.28.22L8 6.94 14.72.22a.75.75 0 0 1 1.06 0z" fillRule="evenodd"></path></svg>
            </Styled.a>
            <Styled.h4 sx={{fontWeight: "heading", ml: 3, my: "0px", py: "0px"}}>Sign Up</Styled.h4>
          </Flex>
          
        </Flex>
        <Flex sx={{width: "100%", alignItems: "center", justifyContent: "center"}} >
          <progress id="file" max="100" sx={{width: "100%"}} value={step/stepCount * 100}> {step/stepCount * 100} </progress>
        </Flex>
        <Box sx={{px: 5, mt: 4}}>

              <UserInfo handleChange={handleChange} values={values} order={1} step={step} />
              <ProgramInfo handleChange={handleChange} values={values} setValues={setValues} order={2} step={step} />
              <PaymentInfo values={values} setValues={setValues} order={3} step={step} />

          <Flex sx={{justifyContent: "space-between", mt: 4}}>
            {previousButton()}
            {nextButton()}       
          </Flex>
        </Box>
      </div>
    </div>
  )
}


