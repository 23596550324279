/** @jsx jsx */
import { jsx, Styled, Grid, Input, Flex} from 'theme-ui'
import React from "react"

export const ProgramInfo = ({step, order, values, setValues, handleChange}) => {
  if (step === order) {
    return(
      <div>
        <Styled.h5>Program Info</Styled.h5>       
        <Grid columns={[2]} gap={4}>
          <Input type="text" name="id" value={values.id} />
          <Input type="text" name="code" value={values.code} />

          <Flex sx={{flexDirection: "column"}}>
            <Styled.h5>You're starting the</Styled.h5>
            <Styled.h3>At-home Bodyweight Program</Styled.h3>
          </Flex>


          {values.program_start_date && (
            <div sx={{gridColumn: "1/-1"}}>
              <Styled.h5>Your start date is</Styled.h5>
              <Styled.h3>{values.program_start_date}</Styled.h3>
              <small onClick={() => {setValues({...values, ["program_start_date"]: ""})}}>Change date</small>
            </div>
          )}

          {!values.program_start_date && (
            <div sx={{gridColumn: "1/-1"}}>
              <Input name="program_start_date" type="date" onChange={handleChange} />
            </div>
          )}


  
        </Grid>
      </div>

    )
  } else {
    return null
  }
}