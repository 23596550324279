import React from "react"
import { SiteProvider } from '../provider'
import {Header, Footer} from "./Navigation"
import { SignUp } from "./Modal"
import "../styles/global.css"


const Layout = ({ children }) => {

  return (
    <SiteProvider>
      <div style={{minHeight: "100vh"}}>
        <main>{children}</main>
      </div>
      <SignUp />
    </SiteProvider>
  )
}


export default Layout
