/** @jsx jsx */

import { jsx, Styled, Grid, Input,} from 'theme-ui'

export const UserInfo = ({step, order, values, handleChange}) => {
  if (step === order) {
    return(
      <div>
        <Styled.h5>User Info</Styled.h5>       
        <Grid columns={[2]} gap={4}>
          {/* <Input placeholder="First Name" name="first_name" value={values.first_name} onChange={handleChange} />
          <Input placeholder="Last Name" name="last_name" value={values.last_name} onChange={handleChange} /> */}
          <div sx={{gridColumn: "1/-1"}}>
            <Input placeholder="Name" name="name" value={values.name} onChange={handleChange} />
          </div>
          <div sx={{gridColumn: "1/-1"}}>
            <Input placeholder="Email" name="email" value={values.email} onChange={handleChange} />
          </div>
          <div sx={{gridColumn: "1/-1"}}>
            <Input placeholder="Username" name="username" value={values.username} onChange={handleChange} />
          </div>
          <div sx={{gridColumn: "1/-1"}}>
            <Input placeholder="**********" type="password" name="password" value={values.password} onChange={handleChange} />
          </div>
          <div sx={{gridColumn: "1/-1"}}>
            <Input placeholder="(888) 488 - 9808" name="phone" value={values.phone} onChange={handleChange} />
          </div>
          <input type="hidden" name="id" value="1" />
          <input type="hidden" name="code" value="1" />
        </Grid>
      </div>

    )
  } else {
    return null
  }
}